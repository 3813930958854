import React from 'react';
import Sidebar from '../../Layouts/Sidebar/Sidebar';
import withAuthorization from '../../Pages/Login/withAuthorization'; 

function PrivatePage() {
    return (
        <div className="App">
            <Sidebar />
        </div>
    );
}

export default withAuthorization(PrivatePage);