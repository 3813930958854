import React, { useState, useEffect } from 'react';
import './Website.scss';
const Website = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://flyydok.com/api-access/wp-json/custom/v1/data/', {
                    headers: {
                        'X-API-KEY': 'my_secure_api_key_123'
                    }
                });
                const data = await response.json();
                if (response.ok) {
                    setData(data);
                } else {
                    setError(data.message);
                }
            } catch (error) {
                console.error('Error fetching API data:', error);
                setError('Error fetching API data');
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            <h1>Data from WordPress API</h1>
            <div id="api-data">
                {error ? (
                    <p>{error}</p>
                ) : (
                    <pre>{JSON.stringify(data, null, 2)}</pre>
                )}
            </div>
        </div>
    );
};

export default Website;
