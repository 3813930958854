import React from 'react';
import './Breadcrumb.scss';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';

function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
}

function Breadcrumb({ items }) {
    return (
        <div role="presentation" onClick={handleClick} className='breadcrumb-holder'>
            <h1 className='title-name'>Dashboard</h1>
            <Breadcrumbs aria-label="breadcrumb">
                {items.map((item, index) => (
                    <Link
                        key={index}
                        underline="hover"
                        color={index === items.length - 1 ? 'text.primary' : 'inherit'}
                        href={item.href}
                        aria-current={index === items.length - 1 ? 'page' : undefined}
                    >
                        {item.label}
                    </Link>
                ))}
            </Breadcrumbs>
        </div>
    );
}

Breadcrumb.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            href: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default Breadcrumb;
