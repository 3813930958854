// src/Pages/Login/Login.js
import React from 'react';
import "./Login.scss";
import { Grid, Paper, Avatar } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import GoogleSignInButton from './GoogleSignInButton';

function Login({ onSignIn }) {
    const paperStyle = { padding: 20, width: 280, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'};
    const avatarStyle = { backgroundColor: '#fff' };

    return (
        <div className="App">
            <Grid>
                <Paper elevation={10} style={paperStyle}>
                    <Grid align="center">
                        <Avatar style={avatarStyle}>
                            <LockIcon />
                        </Avatar>
                        <h2>Log In</h2>
                    </Grid>
                    <GoogleSignInButton onSignIn={onSignIn} />
                </Paper>
            </Grid>
        </div>
    );
}

export default Login;
