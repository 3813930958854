// src/Components/PublicPage/PublicPage.js
import React from 'react';
import Login from '../../Pages/Login/Login';

function PublicPage({ onSignIn }) {
    return (
        <div className="App">
            <Login onSignIn={onSignIn} />
        </div>
    );
}

export default PublicPage;
