// src/Pages/Login/GoogleSignInButton.js
import React from "react";
import { auth, provider } from "../../Config/FirebaseConn";
import { signInWithPopup } from "firebase/auth";
import { Button } from '@mui/material';

const allowedEmails = ["flyydok@gmail.com", "dasbiswajit993@gmail.com"]; // Add allowed emails here

const GoogleSignInButton = ({ onSignIn }) => {
  const btnstyle = { margin: '8px 0' };
  const handleClick = () => {
    signInWithPopup(auth, provider)
      .then((data) => {
        const userEmail = data.user.email;
        if (allowedEmails.includes(userEmail)) {
          localStorage.setItem("email", userEmail);
          onSignIn(userEmail);
        } else {
          alert("Unauthorized email. Access denied.");
        }
      })
      .catch((error) => {
        console.error("Error during sign-in:", error);
        alert("Error during sign-in. Please try again.");
      });
  };

  return (
    <Button color="primary" variant="contained" style={btnstyle} fullWidth onClick={handleClick}>
      Sign in with Google
    </Button>
  );
};

export default GoogleSignInButton;
