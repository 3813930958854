import { initializeApp } from "firebase/app";
import {getAuth,GoogleAuthProvider} from "firebase/auth";
const firebaseConfig = {
    apiKey: "AIzaSyCS-UEafSfawPw0Vs1B2oo2u5RElyW2k1U",
    authDomain: "admin-dashboard-login-access.firebaseapp.com",
    projectId: "admin-dashboard-login-access",
    storageBucket: "admin-dashboard-login-access.appspot.com",
    messagingSenderId: "1050674872693",
    appId: "1:1050674872693:web:a7ccd6c0ace7431f27933c",
    measurementId: "G-MRNJDZJFFE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const provider = new GoogleAuthProvider();
export {auth,provider};