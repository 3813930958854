// src/withAuthorization.js
import React, { useState, useEffect } from 'react';
import PublicPage from '../../Components/PublicPage/PublicPage';

const allowedEmails = ["allowed1@example.com", "allowed2@example.com"]; // Add allowed emails here

function withAuthorization(WrappedComponent) {
  return function WithAuthorizationComponent() {
    const [isAuthorized, setIsAuthorized] = useState(false);

    const handleSignIn = (userEmail) => {
      setIsAuthorized(true);
    };

    useEffect(() => {
      const storedEmail = localStorage.getItem('email');
      if (storedEmail && allowedEmails.includes(storedEmail)) {
        setIsAuthorized(true);
      }
    }, []);

    if (isAuthorized) {
      return <WrappedComponent />;
    } else {
      return <PublicPage onSignIn={handleSignIn} />;
    }
  };
}

export default withAuthorization;
