import React from "react";
import PrivatePage from "../PrivatePage/PrivatePage";

function PageRender() {
  return (
    <div className="App">
      <PrivatePage />
    </div>
  );
}

export default PageRender;
