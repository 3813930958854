import React, { useState, useEffect } from 'react';

const FlipkartApiConn = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('YOUR_API_ENDPOINT_HERE', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            // Add your Flipkart API key to the headers if required
            // 'Authorization': 'Bearer YOUR_API_KEY',
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const responseData = await response.json();
        setData(responseData);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Flipkart API Data</h1>
      {/* Render your data here */}
    </div>
  );
};

export default FlipkartApiConn;
